import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import AviaSalePage from '@/views/AviaSalePage.vue'
import AviaRefundPage from '@/views/AviaRefundPage.vue'
import AviaExchangePage from '@/views/AviaExchangePage.vue'
import AdditionalServicesPage from '@/views/AdditionalServicesPage.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage,
    meta: {
      title: 'Главная'
    },
  },
  {
    path: '/avia_sales',
    name: 'avia_sales',
    component: AviaSalePage,
    meta: {
      title: 'Скрипты на покупку авиабилета'
    },
  },
  {
    path: '/avia_refund',
    name: 'avia_refund',
    component: AviaRefundPage,
    meta: {
      title: 'Скрипты на возврат авиабилета'
    },
  },
  {
    path: '/avia_exchange',
    name: 'avia_exchange',
    component: AviaExchangePage,
    meta: {
      title: 'Скрипты на обмен авиабилета'
    },
  },
  {
    path: '/additional_services',
    name: 'additional_services',
    component: AdditionalServicesPage,
    meta: {
      title: 'Дополнительные услуги'
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Default Title'
})

export default router
