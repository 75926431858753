<script setup>
import { ref } from 'vue'

import AppMenuItem from './AppMenuItem.vue'

const model = ref([
  {
    label: 'Скрипты',
    items: [
      {
        label: 'Продажа авиабилета',
        icon: 'pi pi-fw pi-globe',
        to: '/avia_sales',
      },
      {
        label: 'Возврат билета',
        icon: 'pi pi-fw pi-globe',
        to: '/avia_refund',
      },
      {
        label: 'Обмен авиабилета',
        icon: 'pi pi-fw pi-globe',
        to: '/avia_exchange',
      },
      {
        label: 'Допуслуги',
        icon: 'pi pi-fw pi-globe',
        to: '/additional_services',
      },
    ],
  },
])
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
