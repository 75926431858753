<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
// import { minLength, required } from '@vuelidate/validators'
import { helpers, required } from '@vuelidate/validators'
import axios from 'axios'
// import { useRouter } from 'vue-router'
// import { useMeta } from 'vue-meta'

onMounted(() => {
  // const route = useRouter()
  // console.log(route)
  // const meta = useMeta
  // document.title = meta.title || 'BT-toolbox'
})

const class_services = ref(['эконом', 'комфорт', 'бизнес', 'первый'])

// const dropdownItem = ref(null)

const cities = ['Москва', 'Санкт-Петербург', 'Ижевск', 'Екатеринбург']
const currencies = ['Рубль ₽', 'Евро €', 'Доллар $', 'Юань ¥', 'Тенге ₸']

const city_from = ref(null)
const iata_from = ref(null)
const city_to = ref(null)
const iata_to = ref(null)
const date_from = ref(null)
const date_to = ref(null)
const time_from = ref(null)
const time_to = ref(null)
const avia_company_code = ref(null)
const flight = ref(null)
const back_and_forth = ref(false)
const back_city_from = ref(null)
const back_iata_from = ref(null)
const back_city_to = ref(null)
const back_iata_to = ref(null)
const back_date_from = ref(null)
const back_date_to = ref(null)
const back_time_from = ref(null)
const back_time_to = ref(null)
const back_avia_company_code = ref(null)
const back_flight = ref(null)
const class_service = ref(null)
const fio = ref(null)
const price = ref(null)
const currency = ref(null)
const is_baggage = ref(false)
const script_text = ref(null)

const autoValue = ref(cities)
// const selectedAutoValue = ref(null)
const autoFilteredValue = ref()

const rules1 = computed(() => ({
  city_from: {
    required: helpers.withMessage('Поле обязательное', required),
    // minLength: helpers.withMessage('Минимальная длина 3 символа', minLength(3)),
  },
  iata_from: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Должно быть 3 латинских символа в верхнем регистре',
      helpers.regex(/^[A-Z]{3}$/)
    ),
  },
  city_to: {
    required: helpers.withMessage('Поле обязательное', required),
    // minLength: helpers.withMessage('Минимальная длина 3 символа', minLength(3)),
  },
  iata_to: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Должно быть 3 латинских символа в верхнем регистре',
      helpers.regex(/^[A-Z]{3}$/)
    ),
  },
  date_from: {
    required: helpers.withMessage('Поле обязательное', required),
    // regex: helpers.withMessage(
    //   'должно быть 3 латинских символа в верхнем регистре',
    //   helpers.regex(/^[A-Z]{3}$/)
    // ),
  },
  date_to: {
    required: helpers.withMessage('Поле обязательное', required),
    // regex: helpers.withMessage(
    //   'должно быть 3 латинских символа в верхнем регистре',
    //   helpers.regex(/^[A-Z]{3}$/)
    // ),
  },
  time_from: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Правильный формат времени: 13:00',
      helpers.regex(/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/)
    ),
  },
  time_to: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Правильный формат времени: 13:00',
      helpers.regex(/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/)
    ),
  },
  avia_company_code: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      '2 заглавные буквы русского/латинского алфавита или цифры',
      helpers.regex(/^[A-Z0-9А-Я]{1,2}$/)
    ),
  },
  flight: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage('От 2 до 4 цифр', helpers.regex(/^\d{2,4}$/)),
  },
  class_service: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  fio: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  price: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  currency: {
    required: helpers.withMessage('Поле обязательное', required),
  },
}))

const rules2 = computed(() => ({
  back_city_from: {
    required: helpers.withMessage('Поле обязательное', required),
    // minLength: helpers.withMessage('Минимальная длина 3 символа', minLength(3)),
  },
  back_iata_from: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Должно быть 3 латинских символа в верхнем регистре',
      helpers.regex(/^[A-Z]{3}$/)
    ),
  },
  back_city_to: {
    required: helpers.withMessage('Поле обязательное', required),
    // minLength: helpers.withMessage('Минимальная длина 3 символа', minLength(3)),
  },
  back_iata_to: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Должно быть 3 латинских символа в верхнем регистре',
      helpers.regex(/^[A-Z]{3}$/)
    ),
  },
  back_date_from: {
    required: helpers.withMessage('Поле обязательное', required),
    // regex: helpers.withMessage(
    //   'должно быть 3 латинских символа в верхнем регистре',
    //   helpers.regex(/^[A-Z]{3}$/)
    // ),
  },
  back_date_to: {
    required: helpers.withMessage('Поле обязательное', required),
    // regex: helpers.withMessage(
    //   'должно быть 3 латинских символа в верхнем регистре',
    //   helpers.regex(/^[A-Z]{3}$/)
    // ),
  },
  back_time_from: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Правильный формат времени: 13:00',
      helpers.regex(/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/)
    ),
  },
  back_time_to: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      'Правильный формат времени: 13:00',
      helpers.regex(/^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/)
    ),
  },
  back_avia_company_code: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage(
      '2 заглавные буквы русского/латинского алфавита или цифры',
      helpers.regex(/^[A-Z0-9А-Я]{2}$/)
    ),
  },
  back_flight: {
    required: helpers.withMessage('Поле обязательное', required),
    regex: helpers.withMessage('От 2 до 4 цифр', helpers.regex(/^\d{2,4}$/)),
  },
}))

const searchCity = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      autoFilteredValue.value = [...autoValue.value]
    } else {
      autoFilteredValue.value = autoValue.value.filter((city) => {
        return city.toLowerCase().startsWith(event.query.toLowerCase())
      })
    }
  }, 250)
}

const clearBackCityFrom = () => {
  back_city_from.value = null
}
const clearBackIataFrom = () => {
  back_iata_from.value = null
}

const copyText = () => {
  navigator.clipboard
    .writeText('Уважаемый клиент! \n' + script_text.value)
    .then(() => {
      alert('Текст скопирован в буфер обмена')
    })
    .catch((err) => {
      console.error('Ошибка при копировании текста в буфер обмена', err)
    })
}

watch(back_and_forth, (newValue) => {
  if (newValue) {
    back_city_from.value = city_to.value
    back_iata_from.value = iata_to.value
  }
  if (!newValue) {
    back_city_from.value = null
    back_iata_from.value = null
    back_city_to.value = null
    back_iata_to.value = null
    back_date_from.value = null
    back_date_to.value = null
    back_time_from.value = null
    back_time_to.value = null
    back_avia_company_code.value = null
    back_flight.value = null
  }
})

const onSubmit = () => {
  // console.log(back_and_forth.value)

  v$.value.$touch()
  if (v$.value.$error) return
  if (back_and_forth.value) {
    v2$.value.$touch()
    console.log(v2$)
    console.log(v2$.value.$error)
    if (v2$.value.$error) return
  }
  get_script()
  // alert('Все ок')
}

const get_script = async () => {
  const response = await axios({
    url: '/get_avia_sale_script',
    method: 'POST',
    data: payload.value,
  })
  script_text.value = response.data
  // console.log('response', response)
  // axios
  //   .get('/get_copy_blocks')
  //   .then((res) => {
  //     this.copy_blocks_text = res.data
  //   })
  //   .catch((error) => {
  //     console.error(error)
  //   })
}

const v$ = useVuelidate(rules1, {
  city_from,
  iata_from,
  city_to,
  iata_to,
  date_from,
  date_to,
  time_from,
  time_to,
  avia_company_code,
  flight,
  class_service,
  fio,
  price,
  currency,
})

const v2$ = useVuelidate(rules2, {
  back_city_from,
  back_iata_from,
  back_city_to,
  back_iata_to,
  back_date_from,
  back_date_to,
  back_time_from,
  back_time_to,
  back_avia_company_code,
  back_flight,
})

const payload = ref({
  city_from: city_from,
  iata_from: iata_from,
  city_to: city_to,
  iata_to: iata_to,
  start_date: date_from,
  end_date: date_to,
  time_from: time_from,
  time_to: time_to,
  avia_company_code: avia_company_code,
  flight: flight,
  back_and_forth: back_and_forth,
  back_city_from: back_city_from,
  back_iata_from: back_iata_from,
  back_city_to: back_city_to,
  back_iata_to: back_iata_to,
  back_start_date: back_date_from,
  back_end_date: back_date_to,
  back_time_from: back_time_from,
  back_time_to: back_time_to,
  back_avia_company_code: back_avia_company_code,
  back_flight: back_flight,
  class_service: class_service,
  fio: fio,
  price: price,
  currency: currency,
  is_baggage: is_baggage,
})

const setTestData = () => {
  city_from.value = 'Москва'
  iata_from.value = 'SVO'
  city_to.value = 'Санкт-Петербург'
  iata_to.value = 'LED'
  date_from.value = '2024-05-01'
  date_to.value = '2024-06-01'
  time_from.value = '13:10'
  time_to.value = '14:10'
  avia_company_code.value = 'SU'
  flight.value = '1234'
  class_service.value = 'эконом'
  fio.value = 'Иванов Сергей Анатольевич'
  price.value = '4000'
  currency.value = 'Рубль ₽'
}

const setTestDataBackAndForth = () => {
  city_from.value = 'Москва'
  iata_from.value = 'SVO'
  city_to.value = 'Санкт-Петербург'
  iata_to.value = 'LED'
  date_from.value = '2024-05-01'
  date_to.value = '2024-05-01'
  time_from.value = '13:10'
  time_to.value = '14:10'
  avia_company_code.value = 'SU'
  flight.value = '1234'
  class_service.value = 'эконом'
  fio.value = 'Иванов Сергей Анатольевич'
  price.value = '4000'
  currency.value = 'Рубль ₽'
  back_and_forth.value = true
  back_city_from.value = 'Санкт-Петербург'
  back_iata_from.value = 'LED'
  back_city_to.value = 'Москва'
  back_iata_to.value = 'SVO'
  back_date_from.value = '2024-05-02'
  back_date_to.value = '2024-05-02'
  back_time_from.value = '14:10'
  back_time_to.value = '15:10'
  back_avia_company_code.value = 'SU'
  back_flight.value = '1234'
}
</script>

<template>
  <div class="col-12">
    <!-- {{ result }} -->
    <form>
      <div class="card">
        <h5>Продажа авиабилета</h5>

        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="city_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Город вылета
            </label>
            <AutoComplete
              :class="{ 'p-invalid': v$.city_from.$errors.length }"
              id="dd"
              :dropdown="false"
              :multiple="false"
              v-model="v$.city_from.$model"
              :suggestions="autoFilteredValue"
              @complete="searchCity($event)"
              empty-search-message="    Не найдено"
            />
            <span class="error-message" v-if="v$.city_from.$errors.length">{{
              v$.city_from.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="iata_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код ИАТА аэропорта
              города вылета</label
            >
            <InputText id="iata_from" type="text" v-model="iata_from" />
            <span class="error-message" v-if="v$.iata_from.$errors.length">{{
              v$.iata_from.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="city_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Город прилета</label
            >
            <InputText id="city_to" v-model="city_to" type="text" />
            <span class="error-message" v-if="v$.city_to.$errors.length">{{
              v$.city_to.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="iata_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код ИАТА аэропорта
              города прилета</label
            >
            <InputText id="iata_to" v-model="iata_to" type="text" />
            <span class="error-message" v-if="v$.iata_to.$errors.length">{{
              v$.iata_to.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="date_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Дата вылета</label
            >
            <Calendar
              id="date_from"
              :showIcon="true"
              :showButtonBar="true"
              v-model="date_from"
            ></Calendar>
            <span class="error-message" v-if="v$.date_from.$errors.length">{{
              v$.date_from.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="date_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Дата прилета</label
            >
            <Calendar
              id="date_to"
              :showIcon="true"
              :showButtonBar="true"
              v-model="date_to"
            ></Calendar>
            <span class="error-message" v-if="v$.date_to.$errors.length">{{
              v$.date_to.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="time_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Время вылета</label
            >
            <InputText id="time_from" type="text" v-model="time_from" />
            <span class="error-message" v-if="v$.time_from.$errors.length">{{
              v$.time_from.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="time_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Время прилета</label
            >
            <InputText id="time_to" v-model="time_to" type="text" />
            <span class="error-message" v-if="v$.time_to.$errors.length">{{
              v$.time_to.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="avia_company_code" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код
              авиакомпании</label
            >
            <InputText
              id="avia_company_code"
              v-model="avia_company_code"
              type="text"
            />
            <span
              class="error-message"
              v-if="v$.avia_company_code.$errors.length"
              >{{ v$.avia_company_code.$errors[0].$message }}</span
            >
          </div>

          <div class="field col-12 md:col-6">
            <label for="flight" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Номер рейса</label
            >
            <InputText id="flight" type="text" v-model="flight" />
            <span class="error-message" v-if="v$.flight.$errors.length">{{
              v$.flight.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-12">
            <label for="back_and_forth" class="flex">Билет туда-обратно</label>
            <InputSwitch id="back_and_forth" v-model="back_and_forth" />
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_city_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Город вылета
              (обратно)</label
            >
            <div class="p-inputgroup">
              <InputText
                id="back_city_from"
                type="text"
                v-model="back_city_from"
              />
              <!-- <Button label="Search" /> -->
              <Button icon="pi pi-trash" @click="clearBackCityFrom" />
            </div>

            <span
              class="error-message"
              v-if="v2$.back_city_from.$errors.length"
              >{{ v2$.back_city_from.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_iata_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код ИАТА аэропорта
              города вылета (обратно)</label
            >
            <div class="p-inputgroup">
              <InputText
                id="back_iata_from"
                v-model="back_iata_from"
                type="text"
              />
              <!-- <Button label="Search" /> -->
              <Button icon="pi pi-trash" @click="clearBackIataFrom" />
            </div>

            <span
              class="error-message"
              v-if="v2$.back_iata_from.$errors.length"
              >{{ v2$.back_iata_from.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_city_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Город прилета
              (обратно)</label
            >
            <InputText id="back_city_to" v-model="back_city_to" type="text" />
            <span
              class="error-message"
              v-if="v2$.back_city_to.$errors.length"
              >{{ v2$.back_city_to.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_iata_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код ИАТА аэропорта
              города прилета (обратно)</label
            >
            <InputText id="back_iata_to" v-model="back_iata_to" type="text" />
            <span
              class="error-message"
              v-if="v2$.back_iata_to.$errors.length"
              >{{ v2$.back_iata_to.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_date_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Дата вылета
              (обратно)</label
            >
            <Calendar
              id="back_date_from"
              v-model="back_date_from"
              :showIcon="true"
              :showButtonBar="true"
            ></Calendar>
            <span
              class="error-message"
              v-if="v2$.back_date_from.$errors.length"
              >{{ v2$.back_date_from.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_date_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Дата прилета
              (обратно)</label
            >
            <Calendar
              id="back_date_to"
              v-model="back_date_to"
              :showIcon="true"
              :showButtonBar="true"
            ></Calendar>
            <span
              class="error-message"
              v-if="v2$.back_date_to.$errors.length"
              >{{ v2$.back_date_to.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_time_from" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Время вылета
              (обратно)</label
            >
            <InputText
              id="back_time_from"
              v-model="back_time_from"
              type="text"
            />
            <span
              class="error-message"
              v-if="v2$.back_time_from.$errors.length"
              >{{ v2$.back_time_from.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_time_to" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Время прилета
              (обратно)</label
            >
            <InputText id="back_time_to" v-model="back_time_to" type="text" />
            <span
              class="error-message"
              v-if="v2$.back_time_to.$errors.length"
              >{{ v2$.back_time_to.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_avia_company_code" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Код авиакомпании
              (обратно)</label
            >
            <InputText
              id="back_avia_company_code"
              v-model="back_avia_company_code"
              type="text"
            />
            <span
              class="error-message"
              v-if="v2$.back_avia_company_code.$errors.length"
              >{{ v2$.back_avia_company_code.$errors[0].$message }}</span
            >
          </div>

          <div v-show="back_and_forth" class="field col-12 md:col-6">
            <label for="back_flight" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Номер рейса
              (обратно)</label
            >
            <InputText id="back_flight" v-model="back_flight" type="text" />
            <span class="error-message" v-if="v2$.back_flight.$errors.length">{{
              v2$.back_flight.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="class_service" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Класс
              обслуживания</label
            >
            <Dropdown
              id="class_service"
              v-model="class_service"
              :options="class_services"
            ></Dropdown>
            <span
              class="error-message"
              v-if="v$.class_service.$errors.length"
              >{{ v$.class_service.$errors[0].$message }}</span
            >
          </div>

          <div class="field col-12 md:col-6">
            <label for="fio" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>ФИО Пассажира</label
            >
            <InputText id="iata_from" v-model="fio" type="text" />
            <span class="error-message" v-if="v$.fio.$errors.length">{{
              v$.fio.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="price" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Стоимость
              билета</label
            >
            <InputText id="price" v-model="price" type="text" />
            <span class="error-message" v-if="v$.price.$errors.length">{{
              v$.price.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-6">
            <label for="currency" class="flex"
              ><i class="pi pi-asterisk icon-required"></i>Валюта</label
            >
            <Dropdown
              id="currency"
              v-model="currency"
              :options="currencies"
            ></Dropdown>
            <span class="error-message" v-if="v$.currency.$errors.length">{{
              v$.currency.$errors[0].$message
            }}</span>
          </div>

          <div class="field col-12 md:col-12">
            <label for="is_baggage" class="flex">Багаж включен</label>
            <InputSwitch id="is_baggage" v-model="is_baggage" />
          </div>

          <div class="field col-12"></div>

          <div class="field col-4 md:col-4">
            <Button
              label="Сформировать скрипт"
              class="p-button-outlined mr-2 mb-2"
              @click="onSubmit"
            />
          </div>

          <div class="field col-4 md:col-4">
            <Button
              label="Тестовые данные (туда)"
              class="p-button-outlined p-button-help mr-2 mb-2"
              @click="setTestData"
            />
          </div>

          <div class="field col-4 md:col-4">
            <Button
              label="Тестовые данные (туда-обратно)"
              class="p-button-outlined p-button-help mr-2 mb-2"
              @click="setTestDataBackAndForth"
            />
          </div>
        </div>
      </div>
    </form>

    <div v-if="script_text" class="card mt-4">
      <h5>Уважаемый клиент!</h5>
      <p class="line-height-3 m-0">
        {{ script_text }}
      </p>
      <Button
        label="Скопировать"
        class="p-button-outlined mt-4 col-2"
        @click="copyText"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-required {
  font-size: 0.5rem;
  color: #e24c4c;
  padding-top: 0.25rem;
  padding-right: 0.25rem;
}
.error-message {
  color: #e24c4c;
}
</style>
